import { getCardicianAuthCookie, getCardicianInfoCookie } from './cookieManager'

export const isUserAuthenticated = () => {
  const authCookie = getCardicianAuthCookie()
  const cookieInfo = getCardicianInfoCookie()

  if (!authCookie || !cookieInfo) {
    return false
  }

  // TO-DO
  // Check that that session token in this cookie is the same as the one in the server.
  // If it's not, delete the cookie in the server

  // call api/v1/users/isuserlogged

  return true
}
