export interface ITheme {
  palette: {
    darkPrimary: string
    primary: string
    white: string
    pink: string
    navy: string
    greyGreen: string
    lightGrey: string
    grey: string
    greyPale: string
    darkGreenBlue: string
    slateGray: string
  }
  spacing: {
    unit: number
  }
}

const mainTheme = {
  palette: {
    red: '#f44336',
    green: '#12966a',
    white: '#ffffff',
    $blue: '#4b6ba1',
    grey: '#ebe9ef',
    darkGrey: '#888888',
    black: '#000000',
    background: '#f5f5f5',
    content: '#ffffff',
    text: '#555',
    lightText: '#999',
    shadow: '#cccccc',
    border: '#ddd',
    grisLetra: '#55595c',
    grisClaro: '#cccccc',
    fontFamily: 'Averta, monospace'
  },
  spacing: {
    unit: 8
  }
}

export default mainTheme

/*
$red: #ef593c;
$teal: #39a7b6;
$yellow: #f1a13c;
$blue: #4b6ba1;
$black: #232324;
$lightBlack: #333;
$lightGrey: #f9f9f9;
$semiWhite: #eaeaea;

$grisOscuro: #2d343a;
$grisMedio: #384048;
$grisClaro: #eee;
$grisTitulo: #444;
$grisLetra: #55595c;
*/

/*
$background: #0e2439;
$content: #233b53;
$header: #1f364d;
$darkText: #9cb3c9;
$text: #b9c9da;
$menu: #274059;
*/

/*
$navy: #001F3F;
$blue: #0074D9;
$aqua: #7FDBFF;
$teal: #39CCCC;
$olive: #3D9970;
$green: #2ECC40;
$lime: #01FF70;
$yellow: #FFDC00;
$green: #FF851B;
$red: #FF4136;
$fuchsia: #F012BE;
$purple: #B10DC9;
$maroon: #85144B;
$white: #FFFFFF;
$silver: #DDDDDD;
$gray: #AAAAAA;
$black: #111111;
*/
