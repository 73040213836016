// General
export const INVALID_DATA = 'INVALID_DATA' // 400

// Users
export const NOT_FOUND = 'NOT_FOUND' // 404
export const USERNAME_ALREADY_EXISTS = 'USERNAME_ALREADY_EXISTS' // 400
export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS' // 400
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS' // 400

// Standard Errors
export const INTERNAL_ERROR = 'INTERNAL_ERROR' // 500
export const UNAUTHORIZED = 'UNAUTHORIZED' // 401
