export const HOME_URL = '/'

// Marketing
export const LANDING_PAGE_URL = '/landing-page'

// User
export const LOGIN_URL = '/login'
export const REGISTRATION_URL = '/signup'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const USERS_URL = '/users'
export const USER_PROFILE_URL = '/users/:username'

// Core
export const COMMUNITY_URL = '/community'
export const COURSE_URL = '/course'
export const FEATURED_URL = '/featured'
export const RESOURCES_URL = '/resources'

// Legal
export const PRIVACY_POLICY_URL = '/privacy-policy'
export const TERMS_OF_USE_URL = '/terms'
export const CONTACT_URL = '/contact'

// Errors
export const ERROR_401_URL = '/errors/401'
export const ERROR_404_URL = '*'
