import React, { useContext } from 'react'
import styled from 'styled-components'
import PeopleIcon from '@material-ui/icons/People'
import SchoolIcon from '@material-ui/icons/School'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import { AppContext } from 'store'
import Link from 'components/layout/Link'
import UserMenu from './UserMenu'
import RoutesMenu from './RoutesMenu'
import Logo from 'assets/images/CARDICIERO_LOGO_FORMAL.svg'

import {
  HOME_URL,
  RESOURCES_URL,
  COURSE_URL,
  LOGIN_URL,
  REGISTRATION_URL,
  COMMUNITY_URL
} from 'routes/routes'

interface ActiveProps {
  active?: boolean
}

const Grid = styled.div`
  display: flex;
  padding: 0 20px;
  height: 100%;
`

const HeaderLogoLink = styled(Link)``

const HeaderTitle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  --border: 1px solid green;
  position: relative;
`

const Cell = styled.div`
  --border: 1px solid blue;
  flex: auto;
`

const SLogo = styled.img`
  --border: 1px solid blue;
  height: 35px;
  margin-right: 10px;
`

const Title = styled.span`
  --border: 1px solid red;
  color: ${(p) => p.theme.palette.green};
  font-size: 25px;
  font-family: AvertaExtraBold;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4px;
`

const MenuWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  --border: 1px solid blue;
`

const HeaderOption = styled.div`
  --border: 1px solid red;
  margin-left: 10px;
`

const OptionElement = styled.div<ActiveProps>`
  display: flex;
  cursor: pointer;
  margin-top: 5px;
  height: 60px;
  width: 60px;
  font-size: 14px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.palette.black};
  &:hover {
    border-radius: 7px;
    background: ${(p) => (!p.active ? p.theme.palette.grey : 'none')};
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: 5px;
  }
  --border: 1px solid red;
`

const OptionSelected = styled.div<ActiveProps>`
  background: ${(p) => (p.active ? p.theme.palette.green : 'none')};
  width: 100%;
  margin-top: 2px;
  height: 3px;
`

const SButton = styled.button`
  color: ${(p) => p.theme.palette.white};
  border: none;
  border-radius: 7px;
  box-shadow: none;
  height: 35px;
  outline: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-family: AvertaExtraBold;
  background: ${(p) => p.theme.palette.green};
  margin-left: 10px;
  min-width: 120px;
  &:hover {
    background: rgba(18, 150, 106, 0.7);
  }
  -- p.theme.palette.green '#12966a'
`

const HeaderOptionButton = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`

const SPeopleIcon = styled(PeopleIcon)<ActiveProps>`
  color: ${(p) =>
    p.active ? p.theme.palette.green : p.theme.palette.darkGrey};
`

const SSchoolIcon = styled(SchoolIcon)<ActiveProps>`
  color: ${(p) =>
    p.active ? p.theme.palette.green : p.theme.palette.darkGrey};
`

const SMenuBookIcon = styled(MenuBookIcon)<ActiveProps>`
  color: ${(p) =>
    p.active ? p.theme.palette.green : p.theme.palette.darkGrey};
`

const RoutesHeaderOption = styled(HeaderOption)`
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
  }
`

const CompleteHeader = () => {
  const { state } = useContext(AppContext)
  const isAuthenticated = state.userState.authenticated

  return (
    <Grid>
      <HeaderLogoLink to={HOME_URL}>
        <HeaderTitle>
          <SLogo src={Logo} />
          <Title>Cardicians</Title>
        </HeaderTitle>
      </HeaderLogoLink>
      <Cell />
      {isAuthenticated ? (
        <>
          <MenuWrapper>
            <Link to={COMMUNITY_URL}>
              <HeaderOption>
                <OptionElement
                  active={window.location.pathname === '/community'}
                >
                  <SPeopleIcon
                    active={window.location.pathname === '/community'}
                  />
                </OptionElement>
                <OptionSelected
                  active={window.location.pathname === '/community'}
                />
              </HeaderOption>
            </Link>
            <Link to={COURSE_URL}>
              <HeaderOption>
                <OptionElement active={window.location.pathname === '/course'}>
                  <SSchoolIcon
                    active={window.location.pathname === '/course'}
                  />
                </OptionElement>
                <OptionSelected
                  active={window.location.pathname === '/course'}
                />
              </HeaderOption>
            </Link>
            <Link to={RESOURCES_URL}>
              <HeaderOption>
                <OptionElement
                  active={window.location.pathname === '/resources'}
                >
                  <SMenuBookIcon
                    active={window.location.pathname === '/resources'}
                  />
                </OptionElement>
                <OptionSelected
                  active={window.location.pathname === '/resources'}
                />
              </HeaderOption>
            </Link>
          </MenuWrapper>
          <RoutesHeaderOption>
            <OptionElement>
              <RoutesMenu />
            </OptionElement>
          </RoutesHeaderOption>
          <HeaderOption>
            <OptionElement>
              <UserMenu />
            </OptionElement>
          </HeaderOption>
        </>
      ) : (
        <>
          {/*<HeaderOptionButton>
            <Link to={LOGIN_URL}>
              <SButton>
                Login
              </SButton>
            </Link>
          </HeaderOptionButton>
          <HeaderOptionButton>
            <Link to={REGISTRATION_URL}>
              <SButton>
                Join
              </SButton>
            </Link>
          </HeaderOptionButton>*/}
        </>
      )}
    </Grid>
  )
}

export default CompleteHeader
