import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Page from 'components/Page'
import Link from 'components/layout/Link'
import { getUsers, getVideos } from './logic'
import { USERS_URL } from 'routes/routes'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 30px;
`

export const Box = styled.div`
  border-radius: 7px;
  background: ${(p) => p.theme.palette.white};
  padding: 50px;
  margin: 0 auto;
  margin-bottom: 30px;
  width: 1000px;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`

const UserContainer = styled.div`
  border: 1px solid red;
  padding: 10px;
  margin: 10px;
  display: flex;
`

const UserThumbnail = styled.img`
  height: 35px;
  background: ${(p) => p.theme.palette.darkGrey};
  width: 35px;
  border-radius: 40px;
  display: block;
`

const Community = () => {
  const [users, setUsers] = useState<any>([])
  const [videos, setVideos] = useState<any>([])

  useEffect(() => {
    const initializeUserList = async () => {
      const userList = await getUsers()
      console.log({ userList })
      setUsers(userList)
    }
    initializeUserList()
  }, [])

  useEffect(() => {
    const initializeVideoList = async () => {
      const videoList = await getVideos()
      console.log({ videoList })
      setVideos(videoList)
    }
    initializeVideoList()
  }, [])

  return (
    <Page noMaxWidth>
      <Container>
        <Box>
          <h2>RECENT VIDEOS</h2>
          {videos &&
            videos.map((video: any) => (
              <div>
                userId: {video.userId}
                <br />
                {video.description}
                <br />
                <img
                  src={`https://img.youtube.com/vi/${video.url}/mqdefault.jpg`}
                  alt=""
                />
              </div>
            ))}
        </Box>
        <Box>
          <h2>ONLINE USERS</h2>
          {users &&
            users.map((user: any) => (
              <Link to={USERS_URL + '/' + user.username} key={user.id}>
                <UserContainer>
                  <UserThumbnail src={user.thumbnail} alt="" />
                  {user.username}
                </UserContainer>
              </Link>
            ))}
        </Box>
      </Container>
    </Page>
  )
}

export default Community
