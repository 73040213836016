import { useState, useEffect, useCallback } from 'react'
import GoogleAnalytics from 'react-ga'
import { COOKIES_KEY, loadFromCookie } from './cookies'

const trackingID = 'UA-176613326-1'
let analyticsLoaded = false

export const loadGoogleAnalytics = () => {
  if (analyticsLoaded) {
    return
  }
  if (trackingID) {
    GoogleAnalytics.initialize(trackingID)
    GoogleAnalytics.set({ anonymizeIp: true })
    analyticsLoaded = true

    const page = location.pathname + location.search
    trackPage(page)
  }
}

export const trackPage = async (page: string, options = {}) => {
  const cookiesState = await loadFromCookie(COOKIES_KEY)
  if (!cookiesState) {
    return
  }
  const { acceptedAnalytics } = cookiesState
  if (!acceptedAnalytics || !analyticsLoaded) {
    return
  }
  GoogleAnalytics.set({
    page,
    ...options
  })
  GoogleAnalytics.pageview(page)
}
