import React from 'react'
import { styles } from './styles'

const Faq = () => {
  const classes = styles()
  return (
    <div>
      <p className={classes.question}>Who is this course for?</p>
      <p className={classes.answer}>asdfasdf</p>
      <p className={classes.question}>What if I don't like the course?</p>
      <p className={classes.answer}>asdfasdf</p>
      <p className={classes.question}>Are there any discounts available?</p>
      <p className={classes.answer}>asdfasdf</p>
      <p className={classes.question}>Can I get more details on this course?</p>
      <p className={classes.answer}>asdfasdf</p>
      <p className={classes.question}>
        Something has gone horribly wrong, how can I get help?
      </p>
      <p className={classes.answer}>asdfasdf</p>
    </div>
  )
}

export default Faq
