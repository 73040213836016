import React from 'react'
import styled from 'styled-components'

interface SButtonProps {
  color?: string
  colorHover?: string
  size?: string
}

const SButton = styled.button<SButtonProps>`
  color: ${(p) => p.theme.palette.white};
  border: none;
  border-radius: 7px;
  height: ${(p) => {
    if (p.size === 'big') {
      return '50px'
    } else if (p.size === 'small') {
      return '30px'
    }
  }};
  width: ${(p) => p.size === 'big' && '100%'};
  padding: 0 30px;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-family: AvertaExtraBold;
  background: ${(p) => {
    if (p.color === 'green') {
      return p.theme.palette.green
    }
  }};
  &:hover {
    background: ${(p) => {
      if (p.color === 'green') {
        return `rgba(18, 150, 106, 0.7)` // p.theme.palette.green '#12966a'
      }
    }};
  }
`

interface ButtonComponentProps {
  children: React.ReactNode
  onClick?: any
  color?: string
  colorHover?: string
  size?: 'small' | 'medium' | 'big'
}

const ButtonComponent = ({
  children,
  onClick,
  color,
  colorHover,
  size
}: ButtonComponentProps) => {
  return (
    <SButton
      onClick={onClick}
      color={color}
      colorHover={colorHover}
      size={size}
    >
      {children}
    </SButton>
  )
}

export default ButtonComponent
