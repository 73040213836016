import React from 'react'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import Button from 'components/layout/Button'

const UserMenu = styled.div`
  font-size: 14px;
  &:hover {
    border-radius: 7px;
    background: ${(p) => p.theme.palette.grey};
  }
`

const UserInfo = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid ${(p) => p.theme.palette.grey};
  color: ${(p) => p.theme.palette.black};
`

const UserThumbnail = styled.img`
  height: 35px;
  background: ${(p) => p.theme.palette.darkGrey};
  width: 35px;
  border-radius: 40px;
  display: block;
`

const UserName = styled.div`
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
`

const DropdownContent = styled.div`
  overflow: hidden;
  position: absolute;
  background: ${(p) => p.theme.palette.white};
  box-shadow: 0 0 10px 0 rgba(33, 48, 77, 0.1);
  top: 85px;
  z-index: 1000;
  right: 20px;
  width: 300px;
  border-radius: 7px;
  cursor: default;
`

const MenuOptions = styled.div`
  padding: 15px;
`

const SelectableOptions = styled.div`
  margin-bottom: 15px;
`

const SelectableOption = styled.a`
  border-radius: 7px;
  color: ${(p) => p.theme.palette.black};
  padding: 20px 15px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: block;
  &:hover {
    background: ${(p) => p.theme.palette.grey};
    cursor: pointer;
  }
`

interface LayoutProps {
  dropdownVisible: boolean
  toggleDropdown: any
  userInfo: any
  setLogOut: Function
}

const Layout = ({
  dropdownVisible,
  toggleDropdown,
  userInfo,
  setLogOut
}: LayoutProps) => (
  <UserMenu onClick={toggleDropdown}>
    <UserThumbnail src={userInfo && userInfo.thumbnail} />
    {dropdownVisible && (
      <OutsideClickHandler onOutsideClick={toggleDropdown}>
        <DropdownContent>
          <UserInfo>
            <UserName>{userInfo && '@' + userInfo.username}</UserName>
          </UserInfo>
          <MenuOptions>
            <SelectableOptions>
              <SelectableOption href={'/users/' + userInfo.username}>
                Profile
              </SelectableOption>
              <SelectableOption>Settings</SelectableOption>
            </SelectableOptions>
            <Button color="green" size="big" onClick={setLogOut}>
              Logout
            </Button>
          </MenuOptions>
        </DropdownContent>
      </OutsideClickHandler>
    )}
  </UserMenu>
)

export default Layout
