import React from 'react'
import Page from 'components/Page'
import { Container, Box } from '../styles'

const Contact = () => (
  <Page noMaxWidth lightHeader>
    <Container>
      <Box>
        <h2>Contact</h2>
      </Box>
    </Container>
  </Page>
)

export default Contact
