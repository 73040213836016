import React from 'react'
import Page from 'components/Page'
import PageBlock from 'components/layout/PageBlock'
import ContentBlock from './ContentBlock'
import Prices from './Prices'
import Faq from './Faq'
import { styles } from './styles'

const Course = () => {
  const classes = styles()
  return (
    <Page noMaxWidth>
      <PageBlock background="green">
        <div>
          <p>The wait is over, Cardicians.com is here!</p>
          <p>Seats are limited to 200 globally so be the FIRST TO REGISTER!</p>
          <p>
            Before enrollment into the course, all students will receive a
            welcome email with intructions.
          </p>
        </div>
      </PageBlock>
      <PageBlock background="white">
        <ContentBlock />
      </PageBlock>
      <PageBlock background="grey">
        <ContentBlock />
      </PageBlock>
      <PageBlock background="white" title="This course includes">
        <div>
          <p className={classes.pTitle}>Lifetime access to the course</p>
          <p className={classes.pDetail}>
            asdf adsf asf ads fad fads asdfasdfhaslfj
          </p>
          <p className={classes.pTitle}>Flexible learning program</p>
          <p className={classes.pDetail}>
            asdf adsf asf ads fad fads asdfasdfhaslfj
          </p>
          <p className={classes.pTitle}>
            Get personalized feedback on your submissions
          </p>
          <p className={classes.pDetail}>
            asdf adsf asf ads fad fads asdfasdfhaslfj
          </p>
          <p className={classes.pTitle}>
            Join and participate in the student community
          </p>
          <p className={classes.pDetail}>
            asdf adsf asf ads fad fads asdfasdfhaslfj
          </p>
          <p className={classes.pTitle}>7 days money-back garantee</p>
          <p className={classes.pDetail}>
            asdf adsf asf ads fad fads asdfasdfhaslfj
          </p>
        </div>
      </PageBlock>
      <PageBlock background="green" title="FAQ">
        <Faq />
      </PageBlock>
      <PageBlock title="Pricing plans">
        <Prices />
      </PageBlock>
    </Page>
  )
}

export default Course
