import React from 'react'
import Page from 'components/Page'
import { Container, Message } from '../styles'

const NotFound = () => (
  <Page noMaxWidth>
    <Container>
      <Message>
        <h1>There is nothing here.</h1>
        <h1>No gimmics.</h1>
        <h1>No magnets.</h1>
        <h1>No threads.</h1>
      </Message>
    </Container>
  </Page>
)

export default NotFound
