import React, { useState } from 'react'
import Modal from 'components/Modal'
import Button from 'components/layout/Button'
import { styles } from './styles'

interface PriceCardProps {
  title: string
  subtitle: string
  savings?: string
  note?: string
  options: string[]
  toggleModal: Function
  classes: any
}

const PriceCard = ({
  title,
  subtitle,
  savings,
  note,
  options,
  toggleModal,
  classes
}: PriceCardProps) => (
  <div className={classes.priceCard}>
    <div className={classes.priceCardHeader}>
      <p className={classes.priceCardTitle}>{title}</p>
      <p className={classes.priceCardSubtitle}>{subtitle}</p>
      {note && <p className={classes.priceCardNote}>{note}</p>}
      {savings && <p className={classes.priceCardSavings}>{savings}</p>}
    </div>
    <div className={classes.priceCardBody}>
      <div className={classes.priceCardContent}>
        <ul>
          {options.map((option, i) => (
            <li key={i}>{option}</li>
          ))}
        </ul>
      </div>
    </div>
    <div className={classes.priceCardFooter}>
      <Button size="big" color="green" onClick={toggleModal}>
        Get started
      </Button>
    </div>
  </div>
)

const Prices = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const classes = styles()

  const toggleModal = () => setModalOpen((prevState) => !prevState)

  return (
    <>
      <div className={classes.priceCards}>
        <PriceCard
          title="One time payment"
          subtitle="$295"
          savings="Save 15%"
          options={[
            'Lifetime access to the course',
            'Get personalized feedback on your submissions',
            'Join and participate in the student community',
            '7 days money-back garantee'
          ]}
          toggleModal={toggleModal}
          classes={classes}
        />
        <PriceCard
          title="Monthly payment"
          subtitle="$87/month"
          note="(during 4 months)"
          options={[
            'Lifetime access to the course',
            'Get personalized feedback on your submissions',
            'Join and participate in the student community',
            '7 days money-back garantee'
          ]}
          toggleModal={toggleModal}
          classes={classes}
        />
      </div>
      <Modal open={isModalOpen} onClose={toggleModal}>
        <div>Prices: Modal window</div>
      </Modal>
    </>
  )
}

export default Prices
