import React from 'react'
import { styles } from './styles'

const ContentBlock = () => {
  const classes = styles()
  return (
    <div className={classes.videoAndDescription}>
      <div className={classes.video}>
        <iframe
          title="course"
          width="560"
          height="315"
          src="https://youtube.com/embed/Xm-G6RGyqhA"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      </div>
      <div className={classes.description}>
        <div className={classes.list}>
          <p className={classes.title}>About this course</p>
          <p>Rich learning content</p>
          <p>Student support community</p>
          <p>Performance-based scholarships</p>
          <p>Self-peaced learning</p>
          <p>Learn by doing</p>
        </div>
      </div>
    </div>
  )
}

export default ContentBlock
