import React, { useState } from 'react'
import Layout from './Layout'

const RoutesMenu = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return (
    <Layout dropdownVisible={dropdownVisible} toggleDropdown={toggleDropdown} />
  )
}

export default RoutesMenu
