import { getCardiciansApiUrl } from 'config'

export const getUsers = async () => {
  const url = getCardiciansApiUrl() + '/users'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  let responseValue
  try {
    const response = await fetch(url, { method: 'GET', headers })
    responseValue = await response.json()
    if (response.status === 200) {
      return responseValue.users
    } else if (response.status === 404) {
      return []
    }
  } catch (err) {
    console.error(err)
  }
}

export const getVideos = async () => {
  const url = getCardiciansApiUrl() + '/videos'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  let responseValue
  try {
    const response = await fetch(url, { method: 'GET', headers })
    responseValue = await response.json()
    if (response.status === 200) {
      return responseValue.videos
    } else if (response.status === 404) {
      return []
    }
  } catch (err) {
    console.error(err)
  }
}
