import React from 'react'
import Modal from '@material-ui/core/Modal'
import { styles } from './styles'

interface ModalComponentProps {
  children: React.ReactNode
  open: boolean
  onClose: any
}

const ModalComponent = ({ children, open, onClose }: ModalComponentProps) => {
  const classes = styles()
  return (
    <Modal open={open} onClose={onClose} className={classes.modalWrapper}>
      <div className={classes.modal}>{children}</div>
    </Modal>
  )
}

export default ModalComponent
