export const getColorClassName = (classes: any, className: string) => {
  let selectedClass
  switch (className) {
    case 'green':
      selectedClass = classes.green
      break
    case 'red':
      selectedClass = classes.red
      break
    case 'white':
      selectedClass = classes.white
      break
    case 'grey':
      selectedClass = classes.grey
      break
    case 'black':
      selectedClass = classes.black
      break
    default:
      break
  }
  return selectedClass
}
