import React from 'react'
import styled from 'styled-components'
import AlexElmsley from 'assets/images/magicians/alexelmsley.jpeg'
import ArturoDeAscanio from 'assets/images/magicians/arturodeascanio.jpeg'
import BillMalone from 'assets/images/magicians/billmalone.jpeg'
import DaiVernon from 'assets/images/magicians/daivernon.jpeg'
import Edmarlo from 'assets/images/magicians/edmarlo.jpeg'
import FredKaps from 'assets/images/magicians/fredkaps.jpeg'
import GuyHollingworth from 'assets/images/magicians/guyhollinworth.jpeg'
import Hofzinser from 'assets/images/magicians/hofzinser.jpeg'
import JohnScarne from 'assets/images/magicians/johnscarne.jpeg'
import JuanTamariz from 'assets/images/magicians/juantamariz.jpeg'
import LarryJennings from 'assets/images/magicians/larryjennings.jpeg'
import PaulHarris from 'assets/images/magicians/paulharris.jpeg'
import PepeCarrol from 'assets/images/magicians/pepecarrol.jpeg'
import PitHartling from 'assets/images/magicians/pithartling.jpeg'
import ReneLavand from 'assets/images/magicians/renelavand.jpeg'
import ShinLim from 'assets/images/magicians/shinlim.jpeg'

const MagicianBackground = styled.div`
  z-index: 1;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 74px;
  bottom: 40%;
  padding: 0 2px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  opacity: 0.1;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  & > img {
    padding: 2px;
    border-radius: 15px;
  }
`

const MagiciansGrid = () => (
  <MagicianBackground>
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
    <img src={AlexElmsley} height="60" width="60" alt="AlexElmsley" />
    <img src={ArturoDeAscanio} height="60" width="60" alt="ArturoDeAscanio" />
    <img src={BillMalone} height="60" width="60" alt="BillMalone" />
    <img src={DaiVernon} height="60" width="60" alt="DaiVernon" />
    <img src={Edmarlo} height="60" width="60" alt="Edmarlo" />
    <img src={FredKaps} height="60" width="60" alt="FredKaps" />
    <img src={GuyHollingworth} height="60" width="60" alt="GuyHollingworth" />
    <img src={Hofzinser} height="60" width="60" alt="Hofzinser" />
    <img src={JohnScarne} height="60" width="60" alt="JohnScarne" />
    <img src={JuanTamariz} height="60" width="60" alt="JuanTamariz" />
    <img src={LarryJennings} height="60" width="60" alt="LarryJennings" />
    <img src={PaulHarris} height="60" width="60" alt="PaulHarris" />
    <img src={PepeCarrol} height="60" width="60" alt="PepeCarrol" />
    <img src={PitHartling} height="60" width="60" alt="PitHartling" />
    <img src={ReneLavand} height="60" width="60" alt="ReneLavand" />
    <img src={ShinLim} height="60" width="60" alt="ShinLim" />
  </MagicianBackground>
)

export default MagiciansGrid
