import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles({
  layoutGrid: {
    display: 'grid',
    gridTemplateColumns: `[width-left-space] minmax(20px, auto)
      [width-content] minmax(min-content, 1100px)
      [width-right-space] minmax(20px, auto)`,
    /*gridTemplateColumns: `[width-left-space] 100px
      [width-content] auto
      [width-right-space] 100px`,*/
    height: '100%'
  },
  cell: {
    //border: `3px solid ${green}`
  },
  content: {
    //border: `3px solid ${red}`
  }
})
