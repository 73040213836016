import { getCardiciansApiUrl } from 'config'
import {
  INVALID_DATA,
  USERNAME_ALREADY_EXISTS,
  EMAIL_ALREADY_EXISTS,
  INTERNAL_ERROR
} from 'api/errorMessages'

export const validateForm = (password: string, confirmPassword: string) => {
  // TO-DO This expression must be removed after using OnChange event in Layout file
  if (password !== confirmPassword) {
    return { global: "Passwords don't match" }
  }
}

export const signUpUser = async (
  name: string,
  username: string,
  email: string,
  password: string
) => {
  const url = getCardiciansApiUrl() + '/users/signup'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const body = JSON.stringify({ name, username, email, password })
  let responseValue
  try {
    const response = await fetch(url, { method: 'POST', headers, body })
    responseValue = await response.json()
    if (response.status === 201) {
      return
    }
  } catch (err) {
    return { global: 'Connection error' }
  }
  switch (responseValue.error) {
    case USERNAME_ALREADY_EXISTS:
      return { username: 'Username already registered' }
    case EMAIL_ALREADY_EXISTS:
      return { email: 'Email already registered' }
    case INVALID_DATA:
      return { global: 'Invalid data' }
    case INTERNAL_ERROR:
      return { global: 'Server error' }
    default:
      return { global: 'Unknown error' }
  }
}
