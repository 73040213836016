import React from 'react'
import styled from 'styled-components'
import MaxWidthLayoutWrapper from 'components/MaxWidthLayoutWrapper'
import CompleteHeader from './CompleteHeader'
import LightHeader from './LightHeader'

const SHeader = styled.div`
  background: ${(p) => p.theme.palette.white};
  font-size: 13px;
  height: 70px;
`

interface HeaderProps {
  lightHeader?: boolean
  noMaxWidth?: boolean
}

const Header = ({ lightHeader, noMaxWidth }: HeaderProps) => {
  return (
    <SHeader>
      <MaxWidthLayoutWrapper noMaxWidth={noMaxWidth}>
        {lightHeader ? <LightHeader /> : <CompleteHeader />}
      </MaxWidthLayoutWrapper>
    </SHeader>
  )
}

export default Header
