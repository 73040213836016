import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import AvertaFont from 'assets/fonts/Averta-normal.woff2'
import AvertaExtraBoldFont from 'assets/fonts/Averta-ExtraBold.woff2'

const GlobalStyles = createGlobalStyle`
  ${reset}

  @font-face {
    font-family: 'Averta';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${AvertaFont}) format('woff2');
  }
  
  @font-face {
    font-family: 'AvertaExtraBold';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(${AvertaExtraBoldFont}) format('woff2');
  }

  * {
    box-sizing: border-box;
    --font-family: "Montserrat", "sans-serif";
    font-family: "Averta";
    margin: 0;
    padding: 0;
  }
  
  html,
  body,
  #root {
    --font-family: "Montserrat", "sans-serif";
    font-family: "Averta";
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  img {
    pointer-events: none;
  }
  
  h1,
  h2,
  h3,
  h4 {
    font-family: "AvertaExtraBold";
  }
  
  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }
  
  h1 {
    font-size: 40px;
  }
  
  h2 {
    font-size: 30px;
  }
  
  h3 {
    padding-top: 20px;
    font-size: 25px;
  }
  
  h4 {
    padding-top: 20px;
    font-weight: bold;
    font-size: 20px;
  }
  
  p {
    font-size: 18px;
    font-weight: 200;
  }
  
  li {
    margin-bottom: 20px;
    list-style-type: none;
  }
  li:last-of-type {
    margin-bottom: 0;
  }
  
  .clear {
    clear: both;
  }
`

export default GlobalStyles
