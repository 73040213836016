import styled from 'styled-components'

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 30px;
`

export const Box = styled.div`
  border-radius: 7px;
  background: ${(p) => p.theme.palette.white};
  padding: 50px;
  margin: 0 auto;
  width: 1000px;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`
