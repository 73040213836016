import { getCardiciansApiUrl } from 'config'

export interface IUserProfile {
  name: string
  username: string
  thumbnail: string
  description?: string
  location?: string
  webpage: string
  twitter?: string
  instagram?: string
  facebook?: string
  youtube?: string
  publicEmail?: string
}

export const getUserProfile = async (
  username: string
): Promise<IUserProfile | undefined> => {
  const url = getCardiciansApiUrl() + '/users/' + username
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  let responseValue
  try {
    const response = await fetch(url, { method: 'GET', headers })
    responseValue = await response.json()
    if (response.status === 200) {
      return responseValue.user
    }
  } catch (err) {
    console.error(err)
  }
  return
}

export const findVideosByUsername = async (username: string) => {
  const url = getCardiciansApiUrl() + `/users/${username}/videos`
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  let responseValue
  try {
    const response = await fetch(url, { method: 'GET', headers })
    responseValue = await response.json()
    if (response.status === 200) {
      return responseValue.videos
    } else if (response.status === 404) {
      return []
    }
  } catch (err) {
    console.error(err)
  }
}
