import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center';
  align-items: center;
`

export const Message = styled.div`
  text-align: center;
  background: ${(p) => p.theme.palette.white};
  padding: 50px;
  border-radius: 7px;
  margin: 0 auto;
`
