import Cookies from 'universal-cookie'

const cookies = new Cookies()

export const getCookies = () => {
  return cookies
}

export const getCardicianAuthCookie = () => {
  const cookie = cookies.get('cardicians_session_token')
  return cookie
}

export const getCardicianInfoCookie = () => {
  const cookie = cookies.get('cardicians_session_info')
  return cookie
}
