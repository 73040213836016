import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface SLinkProps {
  color?: string
  colorhover?: string
}

const SLink = styled(Link)<SLinkProps>`
  text-decoration: none;
  color: ${(p) => {
    if (p.color === 'green') {
      return p.theme.palette.green
    } else if (p.color === 'white') {
      return p.theme.palette.white
    }
    return p.theme.palette.green
  }};
  &:hover {
    color: ${(p) => {
      if (p.colorhover === 'green') {
        return `rgba(18, 150, 106, 0.7)` // p.theme.palette.green '#12966a'
      } else if (p.colorhover === 'white') {
        return p.theme.palette.white
      }
      return `rgba(18, 150, 106, 0.7)` // p.theme.palette.green '#12966a'
    }};
  }
`

const Sa = styled.a<SLinkProps>`
  text-decoration: none;
  color: ${(p) => {
    if (p.color === 'green') {
      return p.theme.palette.green
    } else if (p.color === 'white') {
      return p.theme.palette.white
    }
    return p.theme.palette.green
  }};
  &:hover {
    color: ${(p) => {
      if (p.colorhover === 'green') {
        return `rgba(18, 150, 106, 0.7)` // p.theme.palette.green '#12966a'
      } else if (p.colorhover === 'white') {
        return p.theme.palette.white
      }
      return `rgba(18, 150, 106, 0.7)` // p.theme.palette.green '#12966a'
    }};
  }
`

interface LinkComponentProps {
  children: React.ReactNode
  to: string
  color?: string
  colorHover?: string
  external?: boolean
}

const LinkComponent = ({
  children,
  to,
  color,
  colorHover,
  external
}: LinkComponentProps) => {
  return external ? (
    <Sa
      href={to}
      color={color}
      colorhover={colorHover}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Sa>
  ) : (
    <SLink to={to} color={color} colorhover={colorHover}>
      {children}
    </SLink>
  )
}

export default LinkComponent
