import React from 'react'
import cn from 'classnames'
import { getColorClassName } from 'utils/styles'
import { styles } from './styles'

interface PageBlockProps {
  children?: React.ReactNode
  title?: string
  background?: any
}

const PageBlock = ({ children, title, background }: PageBlockProps) => {
  const classes = styles()
  return (
    <div
      className={cn(classes.pageBlock, getColorClassName(classes, background))}
    >
      {title && (
        <div className={classes.pageBlockHeader}>
          <h2>{title}</h2>
        </div>
      )}
      {children}
    </div>
  )
}

export default PageBlock
