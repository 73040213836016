import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles({
  priceCards: {
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 800px)': {
      display: 'block'
    }
  },
  priceCard: {
    background: '#fff',
    borderRadius: '4px',
    maxWidth: '550px',
    margin: '0 20px',
    '@media(max-width: 800px)': {
      width: '100%',
      maxWidth: '100%',
      margin: '0 0 20px 0',
      '&:last-child': {
        margin: '0'
      }
    }
  },
  priceCardHeader: {
    textAlign: 'center',
    padding: '40px 30px'
  },
  priceCardTitle: {
    padding: '3px',
    fontSize: '20px'
  },
  priceCardSubtitle: {
    padding: '10px',
    fontSize: '40px',
    color: '#12966a'
  },
  priceCardSavings: {
    padding: '3px',
    background: '#ecf0f1',
    borderRadius: '4px',
    width: '75px',
    textAlign: 'center',
    fontSize: '12px',
    color: '#888888',
    margin: '0 auto'
  },
  priceCardNote: {
    padding: '3px',
    fontSize: '12px'
  },
  priceCardBody: {
    padding: '0 30px'
  },
  priceCardContent: {
    padding: '30px',
    textAlign: 'center',
    //border: `1px solid ${background}`,
    background: '#ecf0f1',
    borderRadius: '4px'
  },
  priceCardFooter: {
    padding: '30px',
    display: 'flex',
    justifyContent: 'center'
  }
})
