import React from 'react'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import MenuIcon from '@material-ui/icons/Menu'
import Link from 'components/layout/Link'
import { RESOURCES_URL, COURSE_URL, COMMUNITY_URL } from 'routes/routes'

const RoutesMenu = styled.div`
  font-size: 14px;
  &:hover {
    border-radius: 7px;
    background: ${(p) => p.theme.palette.grey};
  }
`

const DropdownContent = styled.div`
  overflow: hidden;
  position: absolute;
  background: ${(p) => p.theme.palette.white};
  box-shadow: 0 0 10px 0 rgba(33, 48, 77, 0.1);
  padding: 15px;
  top: 85px;
  z-index: 1000;
  right: 20px;
  width: 300px;
  border-radius: 7px;
`

const HeaderOption = styled.div`
  display: flex;
  line-height: 35px;
  font-size: 14px;
  text-decoration: none;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.theme.palette.black};
  &:hover {
    border-radius: 7px;
    background: ${(p) => p.theme.palette.grey};
  }
`

interface LayoutProps {
  dropdownVisible: boolean
  toggleDropdown: any
}

const Layout = ({ dropdownVisible, toggleDropdown }: LayoutProps) => (
  <RoutesMenu onClick={toggleDropdown}>
    <MenuIcon fontSize="large" />
    {dropdownVisible && (
      <OutsideClickHandler onOutsideClick={toggleDropdown}>
        <DropdownContent>
          <Link to={COMMUNITY_URL}>
            <HeaderOption>Community</HeaderOption>
          </Link>
          <Link to={COURSE_URL}>
            <HeaderOption>Course</HeaderOption>
          </Link>
          <Link to={RESOURCES_URL}>
            <HeaderOption>Resources</HeaderOption>
          </Link>
        </DropdownContent>
      </OutsideClickHandler>
    )}
  </RoutesMenu>
)

export default Layout
