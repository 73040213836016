import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { LOGIN_URL } from 'routes/routes'
import { history } from 'store'
import { validateForm, signUpUser } from './logic'
import Layout from './Layout'

const Registration = () => {
  const [serverErrors, setServerErrors] = useState({})

  const onSubmit = async (values: any) => {
    const { name, username, email, password, confirmPassword } = values

    const validationErrors = validateForm(password, confirmPassword)
    if (validationErrors) {
      setServerErrors(validationErrors)
      return
    }

    const errors = await signUpUser(name, username, email, password)
    if (errors) {
      setServerErrors(errors)
      return
    }

    history.push({
      pathname: LOGIN_URL,
      state: {
        detail: { newAccountCreated: true }
      }
    })
  }

  return <Layout onSubmit={onSubmit} serverErrors={serverErrors} />
}

export default withRouter(Registration)
