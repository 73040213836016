import React, { useState, useContext, useEffect } from 'react'
import Layout from './Layout'
import { history } from 'store'
import { LOGIN_URL } from 'routes/routes'
import { logOut } from '../utils'
import { AppContext } from 'store'
import { getCardicianInfoCookie } from 'logic/session/cookieManager'

const UserMenu = () => {
  const { dispatch } = useContext(AppContext)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [userInfo, setUserInfo] = useState(undefined)

  useEffect(() => {
    const cookieInfo = getCardicianInfoCookie()
    setUserInfo(cookieInfo)
  }, [])

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  const setLogOut = async () => {
    const isLoggedOut = await logOut()
    if (isLoggedOut) {
      dispatch({ type: 'NOT_LOGGED' })
      history.push({ pathname: LOGIN_URL })
    }
  }

  return (
    <Layout
      setLogOut={setLogOut}
      dropdownVisible={dropdownVisible}
      userInfo={userInfo}
      toggleDropdown={toggleDropdown}
    />
  )
}

export default UserMenu
