import React from 'react'
import Page from 'components/Page'
import { Container, Box } from '../styles'

const TermsOfUse = () => {
  return (
    <Page noMaxWidth lightHeader>
      <Container>
        <Box>
          <h2>Terms of use</h2>
        </Box>
      </Container>
    </Page>
  )
}

export default TermsOfUse
