import React from 'react'
import styled from 'styled-components'

interface IInput {
  borderError: string
}

const SInput = styled.input<IInput>`
  height: 50px;
  padding: 0 15px;
  border-radius: 7px;
  font-size: 15px;
  background: ${(p) => p.theme.palette.white};
  overflow: hidden;
  width: 100%;
  outline-width: 0;
  border: ${(p) =>
    p.borderError ? `1px solid ${p.theme.palette.red}` : '1px solid #ddd'};
`

const Input = ({ borderError, reference, ...props }: any) => {
  return <SInput ref={reference} borderError={borderError} {...props} />
}

export default Input
