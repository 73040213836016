import React, { createContext, useReducer } from 'react'
import { isUserAuthenticated } from 'logic/session/authManager'
import { createBrowserHistory } from 'history'
import { UserStateType, userReducer } from './userReducer'

export const history = createBrowserHistory()

type InitialStateType = {
  userState: UserStateType
}

const initialState: InitialStateType = {
  userState: { authenticated: isUserAuthenticated() }
}

export const AppContext = createContext<{
  state: InitialStateType
  dispatch: React.Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null
})

const mainReducer = ({ userState }: InitialStateType, action: any) => ({
  userState: userReducer(userState, action)
})

export const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
