import { ensureOnce } from 'utils/singleton'
import envConfig from './env.config'
import { CARDICIANS_API_URL, CARDICIANS_SOCKET_URL } from './names'

const envConfiguration = () => {
  return envConfig
}

const getEnvConfig = ensureOnce(envConfiguration)

export const getCardiciansApiUrl = () => {
  const config = getEnvConfig()
  return config[CARDICIANS_API_URL]
}

export const getCardiciansSocketUrl = () => {
  const config = getEnvConfig()
  return config[CARDICIANS_SOCKET_URL]
}
