import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles({
  modalWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modal: {
    background: '#ecf0f1',
    padding: '30px',
    borderRadius: '4px',
    outline: 'none'
  }
})
