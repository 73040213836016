import React from 'react'
import styled from 'styled-components'
import Page from 'components/Page'
import Link from 'components/layout/Link'
import Button from 'components/layout/Button'
import CardiciansLogo from 'assets/images/CARDICIERO_LOGO_FORMAL.svg'
import MagiciansGrid from 'components/MagiciansGrid'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  z-index: 10;
  padding-bottom: 80px;
`

const Slider = styled.div`
  --border: 1px solid red;
  display: flex;
  padding: 20px 20px;
  @media screen and (max-width: 1200px) {
    flex-flow: column;
    align-items: center;
  }
`

const BoxLogo = styled.div`
  @keyframes spinMainLogo {
    25% {
      transform: rotate(1.5deg);
    }
    75% {
      transform: rotate(-1.5deg);
    }
  }
  animation: spinMainLogo 3s linear infinite;
  --border: 1px solid red;
  position: relative;
  height: 400px;
  border-radius: 30px;
  padding: 30px;
  background: ${(p) => p.theme.palette.white};
  @media screen and (max-width: 1200px) {
    @keyframes spinLogoLogin {
      25% {
        transform: rotate(10deg);
      }
      75% {
        transform: rotate(-10deg);
      }
    }
    animation: spinLogoLogin 2s linear infinite;
    height: 80px;
    border-radius: 7px;
    padding: 7px;
  }
`

const BoxDescription = styled.div`
  --border: 1px solid blue;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-left: 100px;
  color: ${(p) => p.theme.palette.grisLetra};
  @media screen and (max-width: 1200px) {
    padding-left: 0;
    padding-top: 40px;
  }
`

const TitleMath = styled.h2`
  --border: 1px solid blue;
  font-size: 40px;
  padding-bottom: 40px;
  font-style: italic;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
`

const Title = styled.h1`
  --border: 1px solid blue;
  font-size: 70px;
  @media screen and (max-width: 1200px) {
    font-size: 30px;
    text-align: center;
  }
`

const SCardiciansLogo = styled.img`
  height: 100%;
`

const LandingPage = () => {
  const items = []

  for (let i = 1; i <= 200; i++) {
    items.push(<div key={i}>{i}</div>)
  }
  return (
    <Page noMaxWidth>
      <MagiciansGrid />
      <Container>
        <Slider>
          <BoxLogo>
            <SCardiciansLogo src={CardiciansLogo} />
          </BoxLogo>
          <BoxDescription>
            <TitleMath>CARDICIAN = CARD + MAGICIAN</TitleMath>
            <Title>
              Meet other
              <br />
              cardicians and
              <br />
              get feedback
              <br />
              from them
            </Title>
          </BoxDescription>
        </Slider>
        <Link to="#">
          {' '}
          {/* "/signup" */}
          <Button size="big" color="green">
            Coming soon{/*Join the community*/}
          </Button>
        </Link>
      </Container>
    </Page>
  )
}

export default LandingPage
