import React from 'react'
import { styles } from './styles'

const MaxWidthLayoutWrapper = ({ children, noMaxWidth }) => {
  const classes = styles()
  return noMaxWidth ? (
    children
  ) : (
    <div className={classes.layoutGrid}>
      <div className={classes.cell} />
      <div className={classes.content}>{children}</div>
      <div className={classes.cell} />
    </div>
  )
}

export default MaxWidthLayoutWrapper
