import React from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import Page from 'components/Page'
import Button from 'components/layout/Button'
import Input from 'components/forms/Input'
import Logo from 'assets/images/CARDICIERO_LOGO_FORMAL.svg'
import MagiciansGrid from 'components/MagiciansGrid'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 10;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  @media (max-width: 600px) : {
    width: 100%;
  }
`

const FlexLine = styled.div`
  margin-bottom: 20px;
`

const CenterFlexLine = styled(FlexLine)`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const BoxLogo = styled.div`
  @keyframes spinLogoForgotPassword {
    25% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-10deg);
    }
  }
  animation: spinLogoForgotPassword 2s linear infinite;
  --border: 1px solid red;
  background: ${(p) => p.theme.palette.white};
  height: 80px;
  border-radius: 7px;
  padding: 7px;
`

const SLogo = styled.img`
  height: 100%;
`

const SubmitError = styled.div`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`

const SButton = styled(Button)`
  align-self: center;
`

interface FormData {
  email: string
}

interface LayoutProps {
  onSubmit: any
  serverErrors: any
}

const Layout = ({ onSubmit, serverErrors }: LayoutProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>()

  return (
    <Page noMaxWidth>
      <MagiciansGrid />
      <Container>
        <Right>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CenterFlexLine>
              <BoxLogo>
                <SLogo src={Logo} />
              </BoxLogo>
            </CenterFlexLine>
            <FlexLine>
              <Input
                name="email"
                type="email"
                placeholder="Email"
                borderError={errors.email || serverErrors.global}
                reference={register({ required: true })}
              />
            </FlexLine>
            {Object.keys(errors).length === 0 && serverErrors.global && (
              <SubmitError>{serverErrors.global}</SubmitError>
            )}
            <FlexLine>
              <SButton size="big" color="green">
                SEND RECOVERY EMAIL
              </SButton>
            </FlexLine>
          </form>
        </Right>
      </Container>
    </Page>
  )
}

export default Layout
