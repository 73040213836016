import React from 'react'
import Page from 'components/Page'
import { Container, Message } from '../styles'

const Unauthorized = () => (
  <Page noMaxWidth>
    <Container>
      <Message>
        <h1>Unauthorized</h1>
      </Message>
    </Container>
  </Page>
)

export default Unauthorized
