export type UserStateType = {
  authenticated: boolean
}

export const userReducer = (state: UserStateType, action: any) => {
  console.log(action.type)
  switch (action.type) {
    case 'LOGGED':
      return {
        ...state,
        authenticated: true
      }
    case 'NOT_LOGGED':
      return {
        ...state,
        authenticated: false
      }
    default:
      throw new Error()
  }
}
