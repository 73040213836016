import { getCardiciansApiUrl } from 'config'
import {
  INVALID_DATA,
  INVALID_CREDENTIALS,
  INTERNAL_ERROR
} from 'api/errorMessages'

export const loginUser = async (email: string, password: string) => {
  const url = getCardiciansApiUrl() + '/users/login'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  const body = JSON.stringify({ email, password })
  let responseValue
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
      credentials: 'include'
    })
    responseValue = await response.json()
    if (response.status === 200) {
      return
    }
  } catch (err) {
    return { global: 'Connection error' }
  }
  switch (responseValue.error) {
    case INVALID_DATA:
      return { global: "Email and password don't match" }
    case INVALID_CREDENTIALS:
      return { global: "Email and password don't match" }
    case INTERNAL_ERROR:
      return { global: 'Server error' }
    default:
      return { global: 'Unknown error' }
  }
}
