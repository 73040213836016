import Cookies from 'universal-cookie'

const cookies = new Cookies()

const PREFIX = `cardicians_v1`
export const COOKIES_KEY = 'cookies'

export type CookiesProps = {
  acceptedNecessary: boolean
  acceptedAnalytics: boolean
  cookieBannerOpen: boolean
}

export const loadFromCookie = async (key: string): Promise<any> => {
  try {
    const value = await cookies.get(`${PREFIX}_${key}`)
    if (value === null || value === undefined) {
      return undefined
    }
    return value
  } catch (err) {
    console.error(`Failed to load ${key} from cookies:`, err)
    return undefined
  }
}

export const saveCookie = async (
  key: string,
  value: any,
  maxAge: number
): Promise<any> => {
  try {
    const stringifiedValue = JSON.stringify(value)
    const expiration = maxAge ? { maxAge } : undefined
    await cookies.set(`${PREFIX}_${key}`, stringifiedValue, expiration)
  } catch (err) {
    console.error(`Failed to save ${key} in cookies:`, err)
  }
}
