import React from 'react'
import Page from 'components/Page'
import { Container, Box } from '../styles'

const PrivacyPolicy = () => (
  <Page noMaxWidth lightHeader>
    <Container>
      <Box>
        <h2>Privacy policy</h2>
      </Box>
    </Container>
  </Page>
)

export default PrivacyPolicy
