import React, { useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { COMMUNITY_URL } from 'routes/routes'
import { AppContext, history } from 'store'
import { loginUser } from './logic'
import Layout from './Layout'

const Login = () => {
  const { dispatch } = useContext(AppContext)
  const [serverErrors, setServerErrors] = useState({})

  const onSubmit = async (values: any) => {
    const { email, password } = values

    const errors = await loginUser(email, password)
    if (errors) {
      setServerErrors(errors)
      return
    }

    dispatch({ type: 'LOGGED' })
    history.push({ pathname: COMMUNITY_URL })
  }

  return <Layout onSubmit={onSubmit} serverErrors={serverErrors} />
}

export default withRouter(Login)
