import React from 'react'
import { Router, Route } from 'react-router'
import styled, { ThemeProvider } from 'styled-components'
import AppRoutes from 'routes'
// import { getSocket, openSocket } from 'logic/socket'
import CookiesBanner from 'logic/analytics/CookiesBanner'
import GlobalStyles from '../../styles/globalStyles'
import theme from '../../styles/theme'
import { AppProvider, history } from 'store'
//import HTMLHead from './HTMLHead'

const LayoutGrid = styled.div`
  display: grid;
  grid-template-rows:
    [header-row] min-content
    [content-row] auto
    [footer-row] min-content;
  min-height: 100%;
  background: ${(p) => p.theme.palette.background};
`

const Root = () => {
  // if (!getSocket()) {
  //   openSocket()
  // }

  return (
    <>
      {/* <HTMLHead title={title} /> */}
      <GlobalStyles />
      <AppProvider>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <LayoutGrid>
              <Route component={AppRoutes} />
            </LayoutGrid>
            <CookiesBanner />
          </Router>
        </ThemeProvider>
      </AppProvider>
    </>
  )
}

export default Root
