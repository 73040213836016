import React from 'react'
import styled from 'styled-components'
import Link from 'components/layout/Link'
import Logo from 'assets/images/CARDICIERO_LOGO_FORMAL.svg'
import { HOME_URL } from 'routes/routes'

const HeaderCenter = styled.div`
  --border: 1px solid red;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderLogoLink = styled(Link)``

interface SLogoProps {
  src: any
}

const HeaderTitle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  --border: 1px solid green;
  position: relative;
`

const SLogo = styled.img`
  --border: 1px solid blue;
  height: 35px;
  margin-right: 10px;
`

const Title = styled.span`
  --border: 1px solid red;
  color: ${(p) => p.theme.palette.green};
  font-size: 25px;
  font-family: AvertaExtraBold;
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 4px;
`

const LightHeader = () => (
  <HeaderCenter>
    <HeaderLogoLink to={HOME_URL}>
      <HeaderTitle>
        <SLogo src={Logo} />
        <Title>Cardicians</Title>
      </HeaderTitle>
    </HeaderLogoLink>
  </HeaderCenter>
)

export default LightHeader
