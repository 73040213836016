import React, { useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import LandingPage from 'routes/marketing/landingPage'
import Course from 'routes/core/course'
import Community from 'routes/core/community'
import Login from 'routes/users/login'
import Registration from 'routes/users/registration'
import ForgotPassword from 'routes/users/forgotPassword'
import UserProfile from 'routes/users/profile'
import PrivacyPolicy from 'routes/legal/privacyPolicy'
import TermsOfUse from 'routes/legal/termsOfUse'
import Contact from 'routes/legal/contact'
import Unauthorized from 'routes/errors/401'
import NotFound from 'routes/errors/404'
import {
  HOME_URL,
  //LANDING_PAGE_URL,
  COURSE_URL,
  LOGIN_URL,
  REGISTRATION_URL,
  FORGOT_PASSWORD_URL,
  USER_PROFILE_URL,
  TERMS_OF_USE_URL,
  PRIVACY_POLICY_URL,
  CONTACT_URL,
  ERROR_401_URL,
  ERROR_404_URL,
  COMMUNITY_URL
} from './routes'
import { trackPage } from 'logic/analytics/googleAnalytics'
import { AppContext } from 'store'

const PrivateRoute = ({ isAuthenticated, component, ...rest }: any) =>
  isAuthenticated ? (
    <Route {...rest} component={component} />
  ) : (
    <Redirect to={LOGIN_URL} />
  )

const AppRoutes = () => {
  const { state } = useContext(AppContext)
  const isAuthenticated = state.userState.authenticated

  useEffect(() => {
    const page = location.pathname + location.search
    trackPage(page)
  }, [location.pathname, location.search, trackPage])

  return (
    <Switch>
      <Route exact path={HOME_URL} component={LandingPage} />

      {/* Core */}
      <PrivateRoute
        exact
        path={COURSE_URL}
        component={Course}
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        exact
        path={COMMUNITY_URL}
        component={Community}
        isAuthenticated={isAuthenticated}
      />

      {/* Users */}
      <Route exact path={LOGIN_URL} component={Login} />
      <Route exact path={REGISTRATION_URL} component={Registration} />
      <Route exact path={FORGOT_PASSWORD_URL} component={ForgotPassword} />
      <PrivateRoute
        exact
        path={USER_PROFILE_URL}
        component={UserProfile}
        isAuthenticated={isAuthenticated}
      />

      {/* Legal */}
      <Route exact path={PRIVACY_POLICY_URL} component={PrivacyPolicy} />
      <Route exact path={TERMS_OF_USE_URL} component={TermsOfUse} />
      <Route exact path={CONTACT_URL} component={Contact} />

      {/* Marketing */}
      {/*
        <Route exact path={LANDING_PAGE_URL} component={LandingPage} />
      */}

      {/* Error */}
      <Route path={ERROR_401_URL} component={Unauthorized} />
      <Route path={ERROR_404_URL} component={NotFound} />
    </Switch>
  )
}

export default AppRoutes
