import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { COMMUNITY_URL } from 'routes/routes'
import { history } from 'store'
import { loginUser } from './logic'
import Layout from './Layout'

const ForgotPassword = () => {
  const [serverErrors, setServerErrors] = useState({})

  const onSubmit = async (values: any) => {
    const { email, password } = values

    const errors = await loginUser(email, password)
    if (errors) {
      setServerErrors(errors)
      return
    }

    history.push({ pathname: COMMUNITY_URL })
  }

  return <Layout onSubmit={onSubmit} serverErrors={serverErrors} />
}

export default withRouter(ForgotPassword)
