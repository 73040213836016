import Cookies from 'universal-cookie'
import { getCardiciansApiUrl } from 'config'

export const logOut = async () => {
  const url = getCardiciansApiUrl() + '/users/logout'
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      credentials: 'include'
    })
    if (response.status === 200) {
      return true
    }
  } catch (err) {
    return { global: 'Connection error' }
  }
  //TO-DO

  const cookies = new Cookies()
  await cookies.remove('cardicians_session_token', { path: '/' })
  await cookies.remove('cardicians_session_info', { path: '/' })

  return false
}
