import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles({
  videoAndDescription: {
    //border: `1px solid green`,
    display: 'flex',
    justifyContent: 'center',
    '@media(max-width: 1000px)': {
      display: 'block'
    }
  },
  description: {
    //border: '1px solid blue',
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'center'
  },
  list: {
    //background: grisClaro,
    //border: `1px solid ${grisClaro}`,
    borderRadius: '4px',
    height: '100%',
    //padding: '20px',
    '@media(max-width: 1000px)': {
      marginTop: '30px',
      width: '100%'
    },
    '& > p': {
      //border: '1px solid blue',
      padding: '0px 30px',
      marginBottom: '30px'
      //background: '#ccc',
      //borderRadius: '4px',
    }
  },
  title: {
    fontSize: '30px',
    textTransform: 'uppercase'
  },
  video: {
    //border: '1px solid red',
    textAlign: 'center',
    height: '315px',
    '@media(max-width: 1000px)': {}
  }
})
