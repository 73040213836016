import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles({
  pageBlock: {
    //border: `1px solid ${red}`,
    padding: '40px 20px'
  },
  pageBlockHeader: {
    //border: `1px solid ${white}`,
    marginBottom: '30px',
    textAlign: 'center'
  },
  white: {
    background: '#fff'
  },
  grey: {
    background: '#ebe9ef'
  },
  grisClaro: {
    background: '#cccccc'
  },
  black: {
    background: '#000'
  },
  green: {
    background: '#12966a'
  },
  red: {
    background: '#f44336'
  }
})
