import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import MailIcon from '@material-ui/icons/MailOutline'
import LanguageIcon from '@material-ui/icons/Language'
import Page from 'components/Page'
import { getUserProfile, IUserProfile, findVideosByUsername } from './logic'

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    'leftBox box rightBox'
    'leftBox box2 rightBox';
  grid-template-columns: 300px auto 300px;
  padding: 30px;
`

const LeftBox = styled.div`
  grid-area: leftBox;
  --border: 1px solid green;
  width: 300px;

  & > img {
    width: 100%;
    border-radius: 7px;
    display: block;
  }
`

const Box = styled.div`
  grid-area: box;
  border-radius: 7px;
  background: ${(p) => p.theme.palette.white};
  padding: 50px;
  margin 0 30px;
  --width: 1000px;
  height: 300px;
`

const Box2 = styled.div`
  grid-area: box2;
  border-radius: 7px;
  background: ${(p) => p.theme.palette.white};
  padding: 50px;
  margin 0 30px;
  margin-top: 30px;
  --width: 1000px;
  min-height: 600px;
`

const RightBox = styled.div`
  grid-area: rightBox;
  border: 1px solid green;
  width: 300px;
`

const SocialBox = styled.div`
  --border: 1px solid blue;
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

const SLink = styled.a`
  --border: 1px solid red;
  display: inline-block;
  color: ${(p) => p.theme.palette.darkGrey};
  padding: 0 10px;
  & > :hover {
    color: ${(p) => p.theme.palette.green};
  }
`

const SkillsList = styled.div`
  --border: 1px solid green;
  display: flex;
  justify-content: center;
`

const Skill = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin: 0 10px;
  height: 120px;
  width: 120px;
  background: ${(p) => p.theme.palette.grey};
  border-radius: 60px;
`

const SkillName = styled.div`
  padding-bottom: 10px;
`

const SkillValue = styled.div`
  font-size: 25px;
  font-weight: bold;
`

const UserProfile = (props: any) => {
  const [user, setUser] = useState<IUserProfile>()
  const [userVideos, setUserVideos] = useState<any>()

  useEffect(() => {
    const initializeProfile = async () => {
      const userProfileData = await getUserProfile(props.match.params.username)
      setUser(userProfileData)
      console.log({ userProfileData })
    }
    initializeProfile()
  }, [props.match.params.username])

  useEffect(() => {
    const initializeUserVideoList = async () => {
      if (!user) {
        return
      }
      const videoList = await findVideosByUsername(user.username)
      console.log({ videoList })
      setUserVideos(videoList)
    }
    initializeUserVideoList()
  }, [user])

  return (
    <Page noMaxWidth>
      <Container>
        {user ? (
          <>
            <LeftBox>
              <img src={user.thumbnail} alt="" />
              <SocialBox>
                {user.publicEmail && (
                  <SLink href={`mailto:${user.publicEmail}`}>
                    <MailIcon />
                  </SLink>
                )}
                {user.webpage && (
                  <SLink target="_blank" href={user.webpage}>
                    <LanguageIcon />
                  </SLink>
                )}
                {user.instagram && (
                  <SLink
                    target="_blank"
                    href={`https://www.instagram.com/${user.instagram}`}
                  >
                    <InstagramIcon />
                  </SLink>
                )}
                {user.youtube && (
                  <SLink
                    target="_blank"
                    href={`https://www.youtube.com/user/${user.youtube}`}
                  >
                    <YouTubeIcon />
                  </SLink>
                )}
                {user.twitter && (
                  <SLink
                    target="_blank"
                    href={`https://twitter.com/${user.twitter}`}
                  >
                    <TwitterIcon />
                  </SLink>
                )}
                {user.facebook && (
                  <SLink
                    target="_blank"
                    href={`http://facebook.com/${user.facebook}`}
                  >
                    <FacebookIcon />
                  </SLink>
                )}
              </SocialBox>
            </LeftBox>
            <Box>
              <h2>
                @{user.username} · {user.name}
              </h2>
              <br />
              {user.description && <div>{user.description}</div>}
              <br />
              {user.location && <div>{user.location}</div>}

              <SkillsList>
                <Skill>
                  <SkillName>Reputation</SkillName>
                  <SkillValue>78/100</SkillValue>
                </Skill>
                <Skill>
                  <SkillName>Technique</SkillName>
                  <SkillValue>8.5/10</SkillValue>
                </Skill>
                <Skill>
                  <SkillName>Presentation</SkillName>
                  <SkillValue>5.3/10</SkillValue>
                </Skill>
                <Skill>
                  <SkillName>Skills</SkillName>
                  <SkillValue>10/10</SkillValue>
                </Skill>
              </SkillsList>
            </Box>
            <Box2>
              {userVideos &&
                userVideos.map((video: any) => (
                  <div key={video.url}>
                    userId: {video.userId}
                    <br />
                    {video.description}
                    <br />
                    <img
                      src={`https://img.youtube.com/vi/${video.url}/mqdefault.jpg`}
                      alt=""
                    />
                  </div>
                ))}
            </Box2>
            <RightBox />
          </>
        ) : (
          <Box>
            <h2>User not found</h2>
          </Box>
        )}
      </Container>
    </Page>
  )
}

export default UserProfile
