import React from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import Page from 'components/Page'
import Button from 'components/layout/Button'
import Link from 'components/layout/Link'
import Input from 'components/forms/Input'
import Logo from 'assets/images/CARDICIERO_LOGO_FORMAL.svg'
import MagiciansGrid from 'components/MagiciansGrid'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 10;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  @media (max-width: 600px) : {
    width: 100%;
  }
`

const FlexLine = styled.div`
  margin-bottom: 20px;
`

const CenterFlexLine = styled(FlexLine)`
  display: flex;
  flex-flow: column;
  align-items: center;
`

const BoxLogo = styled.div`
  @keyframes spinLogoJoin {
    25% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-10deg);
    }
  }
  animation: spinLogoJoin 2s linear infinite;
  --border: 1px solid red;
  background: ${(p) => p.theme.palette.white};
  height: 80px;
  border-radius: 7px;
  padding: 7px;
`

const SLogo = styled.img`
  height: 100%;
`

const SubmitError = styled.div`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`

const SButton = styled(Button)`
  align-self: center;
`

interface FormData {
  name: string
  username: string
  email: string
  password: string
  confirmPassword: string
}

interface LayoutProps {
  onSubmit: any
  serverErrors: any
}

const Layout = ({ onSubmit, serverErrors }: LayoutProps) => {
  const { register, handleSubmit, errors } = useForm<FormData>()

  return (
    <Page noMaxWidth>
      <MagiciansGrid />
      <Container>
        <Right>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CenterFlexLine>
              <BoxLogo>
                <SLogo src={Logo} />
              </BoxLogo>
            </CenterFlexLine>
            <FlexLine>
              <Input
                name="name"
                type="text"
                placeholder="Name"
                borderError={errors.name}
                reference={register({
                  required: true,
                  minLength: 3,
                  maxLength: 30
                })}
              />
            </FlexLine>
            {errors.name && errors.name.type === 'minLength' && (
              <SubmitError>Min 3 characters</SubmitError>
            )}
            {errors.name && errors.name.type === 'maxLength' && (
              <SubmitError>Max 30 characters</SubmitError>
            )}
            <FlexLine>
              <Input
                name="username"
                type="text"
                placeholder="Username"
                borderError={errors.username || serverErrors.username}
                reference={register({
                  required: true,
                  minLength: 3,
                  maxLength: 25
                })}
              />
            </FlexLine>
            {errors.username && errors.username.type === 'minLength' && (
              <SubmitError>Min 3 characters</SubmitError>
            )}
            {errors.username && errors.username.type === 'maxLength' && (
              <SubmitError>Max 25 characters</SubmitError>
            )}
            {Object.keys(errors).length === 0 && serverErrors.username && (
              <SubmitError>{serverErrors.username}</SubmitError>
            )}
            <FlexLine>
              <Input
                name="email"
                type="email"
                placeholder="Email"
                borderError={errors.email || serverErrors.email}
                reference={register({ required: true })}
              />
            </FlexLine>
            {Object.keys(errors).length === 0 && serverErrors.email && (
              <SubmitError>{serverErrors.email}</SubmitError>
            )}
            <FlexLine>
              <Input
                name="password"
                type="password"
                placeholder="Password"
                borderError={errors.password}
                reference={register({
                  required: true,
                  minLength: 8,
                  maxLength: 50
                })}
              />
            </FlexLine>
            {errors.password && errors.password.type === 'minLength' && (
              <SubmitError>Min 8 characters</SubmitError>
            )}
            {errors.password && errors.password.type === 'maxLength' && (
              <SubmitError>Max 50 characters</SubmitError>
            )}
            <FlexLine>
              <Input
                name="confirmPassword"
                type="password"
                placeholder="Confirm password"
                borderError={errors.confirmPassword}
                reference={register({
                  required: true,
                  minLength: 8,
                  maxLength: 50
                })}
              />
            </FlexLine>
            {errors.confirmPassword &&
              errors.confirmPassword.type === 'minLength' && (
                <SubmitError>Min 8 characters</SubmitError>
              )}
            {errors.confirmPassword &&
              errors.confirmPassword.type === 'maxLength' && (
                <SubmitError>Max 50 characters</SubmitError>
              )}
            {Object.keys(errors).length === 0 && serverErrors.global && (
              <SubmitError>{serverErrors.global}</SubmitError>
            )}
            <FlexLine>
              <SButton size="big" color="green">
                Join
              </SButton>
            </FlexLine>
            <FlexLine>
              Already have an account? <Link to="/login">Sign in</Link>
            </FlexLine>
          </form>
        </Right>
      </Container>
    </Page>
  )
}

export default Layout
