import React from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'
import MaxWidthLayoutWrapper from 'components/MaxWidthLayoutWrapper'

interface PageProps {
  children: React.ReactNode
  noMaxWidth?: boolean
  lightHeader?: boolean
}

const Page = ({ children, noMaxWidth, lightHeader }: PageProps) => (
  <>
    <Header noMaxWidth={noMaxWidth} lightHeader={lightHeader} />
    <MaxWidthLayoutWrapper noMaxWidth={noMaxWidth}>
      {children}
    </MaxWidthLayoutWrapper>
    {/*<Footer noMaxWidth={noMaxWidth} />*/}
  </>
)

export default Page
